function getModifierPrice ({price, priceIf}) {
  let priceDisplay
  if (Array.isArray(priceIf)) {
    let result = priceIf.find(({match}) => customerPicks[match])
    priceDisplay = result && result.price || 0
  } else {
    priceDisplay = price || 0
  }
  return priceDisplay
}

function getModifierSummary ({modifiers, customerPicks}) {
  let totalModifierPrice = 0
  let totalModifierCount = 0
  modifiers.forEach(mod => {
    let picked = customerPicks[mod.id]
    if (picked) {
      let quantity = isNaN(Number(picked.quantity)) ? 1 : picked.quantity
      let pMPrice = getModifierPrice(mod) * quantity
      totalModifierPrice += pMPrice
      totalModifierCount += quantity
    }

  })
  return {totalModifierCount, totalModifierPrice}
}

export function validateCustomerPicksForProduct (product) {
  // const {customerPicks={}, modgroups=[]} = product
  const customerPicks = product.customerPicks || {}
  const modgroups     = product.modgroups     || []
  const resultsMap = {}
  const resultsArray = []

  modgroups.forEach(({id: modgroupId, required, minCount, minPrice, maxCount, modifiers, type}) => {
    if (!modifiers || (modifiers.length === 0)) { return } // need to contain modifiers
    if (required) {
      const pickedModifier = modifiers.find(({id: modifierId}) => {
        return customerPicks[modifierId]
      })
      if (!pickedModifier) {
        // customer did not make a requried selection
        const errorObj = { modgroupId, message: 'This selection is required' }
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }
    if (minCount) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      console.log({totalModifierCount, totalModifierPrice})
      if (totalModifierCount < minCount) {
        const errorObj = { modgroupId, message: `Minimum Selection: ${minCount}`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (maxCount) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      console.log({totalModifierCount, totalModifierPrice})
      if (totalModifierCount > maxCount) {
        const errorObj = { modgroupId, message: `Maximum Selection: ${maxCount}`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }

    if (minPrice) {
      const {totalModifierCount, totalModifierPrice} = getModifierSummary({modifiers, customerPicks, type})
      if (Number(totalModifierPrice) < Number(minPrice)) {
        const errorObj = { modgroupId, message: `Minimum Price: $${min}`}
        resultsMap[modgroupId] = errorObj
        resultsArray.push(errorObj)
      }
    }



  })

  return { resultsMap, resultsArray, validated: (resultsArray.length === 0) }
  // check if
}
